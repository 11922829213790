import React, { FC, ReactElement, useContext } from "react";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import { Layer } from "grommet";

interface CustomModalProps {
  children: ReactElement;
}

const CustomModal: FC<CustomModalProps> = ({ children }) => {
  const dispatch = useContext(GlobalDispatchContext);
  const handleClose = () => {
    dispatch({
      type: "TOGGLE_CONTACT",
      payload: {
        contactLayer: false,
      },
    });
  };
  return (
    <Layer
      onEsc={() => handleClose()}
      onClickOutside={() => handleClose()}
      className="sm:w-100"
    >
      {children}
    </Layer>
  );
};

export default CustomModal;
