import React from "react";
import footerLink from "./footer.json";
import { FaLinkedinIn } from "react-icons/fa";
import Bimeco from "assets/svgs/bemico.svg";
import "./footer.css";

let viewerUrl = process.env.GATSBY_VIEWER_URL;

// Replace "YOUR_VIEWER_URL" with the actual URL
footerLink.account.forEach((service) => {
  if (service.label === "Free IFC Viewer") {
    service.link = viewerUrl;
  }
});

const FooterCard = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="py-10 bg-white md:py-16">
      <div className="tw-container">
        <div className="flex flex-col items-start justify-between gap-8 lg:flex-row">
          <div className="lg:w-[384px]">
            <Bimeco />
            <p className="my-3 text-sm text-gray-500 md:text-base md:my-4">
              The World's Leading BIM Services Provider
            </p>

            <ul className="flex items-center gap-3 mt-4 list-none md:gap-4 lg:gap-6 md:mt-6 lg:mt-8">
              <li>
                <a
                  href="https://www.linkedin.com/company/bim-engineering-construction-sg/"
                  className="text-gray-400 transition-all duration-300 ease-in-out hover:text-main-primary hover:opacity-80"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-start justify-between w-full gap-8 lg:flex-1 md:flex-row">
            <div>
              <p className="text-gray-400 text-sm font-semibold tracking-[0.7px] uppercase mb-3 md:mb-4">
                Services
              </p>
              <ul className="flex flex-col gap-2 list-none md:gap-3 lg:gap-4">
                {footerLink.services.map((service, index) => (
                  <li key={index}>
                    <a
                      href={service.link}
                      className="text-sm text-gray-700 transition-all duration-300 ease-in-out md:text-base hover:text-main-primary"
                    >
                      {service.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <p className="text-gray-400 text-sm font-semibold tracking-[0.7px] uppercase mb-3 md:mb-4">
                Solutions
              </p>
              <ul className="flex flex-col gap-2 list-none md:gap-3 lg:gap-4">
                {footerLink.solutions.map((service, index) => (
                  <li key={index}>
                    <a
                      href={service.link}
                      className="text-sm text-gray-700 transition-all duration-300 ease-in-out md:text-base hover:text-main-primary"
                    >
                      {service.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <p className="text-gray-400 text-sm font-semibold tracking-[0.7px] uppercase mb-3 md:mb-4">
                Company
              </p>
              <ul className="flex flex-col gap-2 list-none md:gap-3 lg:gap-4">
                {footerLink.company.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.link}
                      className="text-sm text-gray-700 transition-all duration-300 ease-in-out md:text-base hover:text-main-primary"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="text-gray-400 text-sm font-semibold tracking-[0.7px] uppercase mb-3 md:mb-4">
                Quick Links
              </p>
              <ul className="flex flex-col gap-2 list-none md:gap-3 lg:gap-4">
                {footerLink.account.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.link}
                      className="text-sm text-gray-700 transition-all duration-300 ease-in-out md:text-base hover:text-main-primary"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-8 border-0 border-t border-gray-200 border-solid mt-11">
          <p className="mb-0 text-sm text-center text-gray-400 md:text-base">
            ©{year} BIM ENGINEERING & CONSTRUCTION (UEN:199602472G).
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterCard;
